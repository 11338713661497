@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input,
  textarea,
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    transition: outline 0.3s !important;
    transition: border-color 0.3s !important;
  }

  [type="checkbox"]:focus,
  [type="radio"]:focus,
  button:focus {
    @apply !ring-0;
    box-shadow: none;
  }

  a {
    @apply outline-none;
  }
}

TalentFontBox {
  display: none !important;
}

html,
body {
  @apply tabular-nums;
  min-width: 1400px;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overscroll-behavior: none;
}

.rdp {
  @apply w-fit shadow rounded p-3 !ml-0 relative z-50 bg-white;
  --rdp-cell-size: 30px !important;
}

.rdp-cell {
  @apply !p-1;
}

.rdp-button {
  @apply !rounded-md !transition-colors !duration-300;
}

.rdp-day_selected {
  @apply !bg-primary;
}

.rdp-day_range_middle {
  @apply !bg-primary;
}
